import "./Tabs.css";


function Tabs({filterCategory, tabsData}){

    return(
        <>
          <div className="tabs_space">
          {
          tabsData.map((category, index)=>{
                return (
                  <button type="button" className="btn_tabs" onClick={()=> filterCategory(category)} key={index}>{category}</button>
                )
          })
          }
          </div>
        </>
          )
}

export default Tabs;