import './App.css';
import Header from './components/Header.js';
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
import Home from './components/Home.js';
import About from './components/About.js';
import Services from './components/Services.js';
import Work from './components/Work.js';
import nh72020 from './projects/nh72020.js';

import Footer from './components/Footer.js';
import ComingSoon from './components/ComingSoon.js';
import Connect from './components/Connect.js';
import scrollTop from './Assets/scrollToTop.png'
import { gsap } from "gsap";

import * as THREE from 'three';

import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

//without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
const scene = new THREE.Scene();
function App() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <Router>
        <div className="App">
        <div className="scroll-to-top">
       {isVisible && 
        <div onClick={scrollToTop}>
          <img className="scroll-top" src={scrollTop} alt='Go to top'/>
        </div>}
        <Header/>
        <Switch>
          <Route exact path="/" component={Home}  >
          </Route>
          <Route exact path="/about" component={About} >
          </Route>
          <Route exact path="/services" component={Services} >
          </Route>
          <Route exact path="/work" component={Work} >
          </Route>
          <Route exact path="/comingsoon" component={ComingSoon} >
          </Route>
          <Route exact path="/connect" component={Connect} >
          </Route>
          <Route exact path="/nh72020" component={nh72020} >
          </Route>
        </Switch>
        <Footer/>
        </div>
        </div>
    </Router>
  );
}
AOS.init();
export default App;
