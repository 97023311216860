import React  from 'react'
import './Header.css'
import Logo from "../Assets/logo.svg";
import { Navbar,Nav, Container} from 'react-bootstrap';

function Header() {
    
    return (
        <div>
            <Navbar fixed="top" bg="light"  expand="lg">
                <Container className='bgNav'>
                    <Navbar.Brand href="/">
                        <img className="styleLogo" alt="Codecartel Logo" src={Logo}></img>
                    </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll"/>
                        <Navbar.Collapse id="navbarScroll">
                        <Nav className="autoAlign">
                            	<ul className="menu">
                                <li className="menu__item">
                                    <a href="/" className="menu__link">
                                        <span className="menu__title">
                                            <span className= "menu__first-word" data-hover="Home">
                                                Home
                                            </span>
                                        </span>
                                    </a>
                                </li>
                        
                                <li className="menu__item">
                                    <a href="/about" className="menu__link">
                                        <span className="menu__title">
                                            <span  className= "menu__first-word" data-hover="About">
                                                About
                                            </span>
                                        </span>
                                    </a>
                                </li>
                        
                                <li className="menu__item">
                                    <a href="/services" className="menu__link">
                                        <span className="menu__title">
                                            <span className="menu__first-word" data-hover="Services">
                                                Services
                                            </span>
                                        </span>
                                    </a>
                                </li>
                        
                                <li className="menu__item">
                                    <a href="/comingSoon" className="menu__link">
                                        <span className="menu__title">
                                            <span className="menu__first-word" data-hover="Work">
                                                Work
                                            </span>
                                        </span>
                                    </a>
                                </li>
                                {/* <li className="menu__item">
                                    <a href="/ComingSoon" className="menu__link">
                                        <span className="menu__title">
                                            <span className="menu__first-word" data-hover="Learn">
                                                Learn
                                            </span>
                                        </span>
                                    </a>
                                </li> */}
                                <li className="menu__item">
                                    <a href="/connect" className="menu__link">
                                        <span className="menu__title">
                                            <span className="menu__first-word " data-hover="Connect">
                                                Connect
                                            </span>
                                        </span>
                                        <div className="lineAnimate"></div>
                                    </a>
                                </li>
                            </ul>
                        </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    )
}

export default Header