import React, { useEffect, useRef }  from 'react';
import "./nh72020.css";
import nh72020tile from "../Assets/projects/nh72020/nh7main.gif";

import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Row, Col} from 'react-bootstrap';

function nh72020() {

 
    return (
        <div>
            <section className='proj_parent_block'>
                <Container>
                    <div className='proj_detail_main'>
                        <h1 className='proj_title_main'>Bacardi NH7 Weekender 2020</h1>
                        <p className='proj_tagline'>Helping Meta understand how Indian teens and their parents use the internet</p>
                        
                    </div>
                </Container>
                <img src={nh72020tile} className='main_hero_proj'></img>
            </section>
        </div>
    )
}

export default nh72020