import "./Items.css";


function Items({data}){
    return (
        <div className="row ">
        {
          data.map((value)=>{
           const {id, image, title, cat_bel, description,link, colorcard} = value;
           return (
            <div className="col-sm-6 project_div" key={id}>
                <a className="project_btn" href={link}>
                <div className="card style_custom text-center" style={{ width:"100%"}}>
                    <img src={image} className="img-fluid project_img" style={{height:"200px", width:"100%"}}/>
                    <h3 className="project_title">{title}</h3>
                    <p className="project_mini_descp">{description}</p>
                    <p className="project_mini_descp_bel">{cat_bel}</p>
                </div>
                </a>
            </div>
           )
          })
        }
</div>
    )
}

export default Items;