import nh72020tile from "../Assets/projects/nh72020/tile.png";
import kvifftile from "../Assets/projects/kviff/tile.png";

const data =[
    {
        id:1,
        image: nh72020tile,
        title:"NH7 Weekender 2022",
        description:"An immersive website for a tech-forward investment firm ",
        cat_bel:"Website Development, Photo-booth, Interactive Games",
        category:"Design",
        link:"/nh72020"
       
    },
    {
        id:2,
        image:kvifftile,
        title:"KVIFF",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        cat_bel:"UX/UI Design, Website Development",
        category:"Design",
        link:"/about"
    },
    {
        id:3,
        image:"https://online.berklee.edu/takenote/wp-content/uploads/2020/11/what_music_producers_do_article_image_2020.jpg",
        title:"KVIFF",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        cat_bel:"Website Development, Photobooth, Interactive Games",
        category:"Design",
        link:"/about"
    },
    {
        id:4,
        image:"https://online.berklee.edu/takenote/wp-content/uploads/2020/11/what_music_producers_do_article_image_2020.jpg",
        title:"Aria Healthcare",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"Design",
        link:"/about"
    },
    {
        id:5,
        image:"./images/bootstrap.jpg",
        title:"Catotech Systems",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"Design",
        link:"/about"
    },
    {
        id:6,
        image:"./images/php.jpg",
        title:"PHP Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"development",
        link:"/about"
    },
    {
        id:7,
        image:"./images/node-js.jpg",
        title:"Node.js Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"development",
        link:"/about"
    },
    {
        id:8,
        image:"./images/asp.jpg",
        title:"ASP Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"development",
        link:"/about"
    },
    {
        id:9,
        image:"./images/sql.jpg",
        title:"SQL Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"branding",
        link:"/about"
    },
    {
        id:10,
        image:"./images/mongodb.jpg",
        title:"Mongo DB Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"branding",
        link:"/about"
    },
    {
        id:11,
        image:"./images/c.jpg",
        title:"C Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"photobooth",
        link:"/about"
    },
    {
        id:12,
        image:"./images/c-plus.jpg",
        title:"C++ Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"photobooth",
        link:"/about"
    },
    {
        id:13,
        image:"./images/java.jpg",
        title:"Java Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"photobooth",
        link:"/about"
    },
    {
        id:14,
        image:"./images/python.jpg",
        title:"Python Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"photobooth",
        link:"/about"
    },
    {
        id:15,
        image:"./images/go.jpg",
        title:"Go Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        category:"photobooth",
        link:"/about"
    },
]

export default data;